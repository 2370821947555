import './App.css';
import AttackLayer3 from './attack-layer3';
import PopularDomains from './popular-domains';
import TrafficChange from './traffic-change';

function App() {

  return (
    <div className="App">
      <TrafficChange/>
      <AttackLayer3/>
      <PopularDomains/>
    </div>
  );
}

export default App;
