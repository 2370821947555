import React from "react"

class PopularDomains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    fetch("https://my-project.qiudanwang.workers.dev/popular-domains")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.rankingEntries.sort((a, b) => {return a.rank - b.rank;})});
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <fieldset className="TableField">
          <legend>
            Popular Domains Ranked by the Domain Rank
          </legend>
          
        <table className='DomainTable'>
          <thead>
            <tr>
              <th>Rank</th>
              <th>RankChange</th>
              <th>Domain</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr>
                <td>{item.rank}</td>
                <td>{item.rankChange}</td>
                <td>{item.domain}</td>
                <td>{item.Category}</td>
              </tr>
            ))}
          </tbody>
        </table>

        </fieldset>
      );
    }
  }
}

export default PopularDomains;