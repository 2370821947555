import React from "react"
import ReactECharts from 'echarts-for-react';

class AttackLayer3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      time_keys: [],
      total_values: []
    };
  }

  componentDidMount() {
    fetch("https://my-project.qiudanwang.workers.dev/attack-layer3")
      .then(res => res.json())
      .then(
        (result) => {
          var data_time_keys = [];
          var data_total_values = [];
          var i = 0;
          while (i in result.data.total.timestamps) {
            data_time_keys.push(result.data.total.timestamps[i]);
            data_total_values.push(result.data.total.values[i]);
            i += 1;
          }
          this.setState({
            isLoaded: true,
            time_keys: data_time_keys,
            total_values: data_total_values
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, time_keys, total_values } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const options = {
        title: {
          text: "Layer 3 DDoS Attack over the last 30 Days",
          x: 'center', top: '5%'
        },
        grid: { top: '20%', right: '10%', left: '10%'},
        xAxis: {
          type: 'category',
          data: time_keys,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Attack Volume',
            data: total_values,
            type: 'line',
            smooth: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      };

      return <ReactECharts option={options}/>;
    }
  }
}

export default AttackLayer3;